<template>
  <b-container fluid class="component-autocomplete-input px-0 py-0">
    <b-form-input ref="Input" v-model="content" autocomplete="off" :state="state" :disabled="disabled" @input="onValueInput" @blur="onBlur" @focus="onFocus" @keyup="onKeyUp" @mouseup="onMouseUp" />
    <div class="autocomplete-list-container" v-show="filteredAutocompleteValues.length">
      <ul class="autocomplete-list px-0">
        <li v-for="item, index in filteredAutocompleteValues" :key="index" class="px-3 py-1" :class="{ active: index === selectionIndex }" @click="() => onItemClick(item)">{{ item }}</li>
      </ul>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'AutocompleteInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    values: {
      type: Array,
      default: () => { return [] }
    },
    state: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      content: '',
      isFocused: false,
      cursorPosition: 0,
      selectionIndex: -1
    }
  },
  computed: {
    filteredAutocompleteValues () {
      if (!this.content || !this.isFocused) {
        return []
      }

      let contentFragment = this.content
      const spaceIds = [0]
      const matches = [...this.content.matchAll(/\s/g)]
      matches.map(item => {
        spaceIds.push(item.index)
      })
      spaceIds.push(this.content.length)

      if (spaceIds.length > 2) {
        let start = 0
        let len = spaceIds[1]
        for (const i in spaceIds) {
          if (this.cursorPosition - spaceIds[i] < 1) {
            break
          }
          switch (i) {
            case '0': {
              start = 0
              len = spaceIds[1]
              break
            }
            default:
              start = spaceIds[i] + 1
              len = spaceIds[Number(i) + 1] - start
          }
        }
        contentFragment = this.content.substr(start, len)
      }
      if (!contentFragment) {
        return []
      }

      const re = new RegExp(`${contentFragment.toLowerCase()}`)
      return this.values.filter(val => re.test(val.toLowerCase()))
    }
  },
  methods: {
    onItemClick (value) {
      this.content = value
      this.$emit('input', this.content)
    },
    onValueInput (e) {
      this.$emit('input', this.content)
    },
    onBlur () {
      setTimeout(() => {
        this.isFocused = false
      }, 400)
    },
    onFocus (e) {
      this.cursorPosition = e.srcElement.selectionStart
      setTimeout(() => {
        this.isFocused = true
        this.selectionIndex = -1
      }, 300)
    },
    onKeyUp (e) {
      this.cursorPosition = e.srcElement.selectionStart
      switch (e.keyCode) {
        case 13: // Enter
          if (this.selectionIndex > -1 && this.selectionIndex < this.filteredAutocompleteValues.length) {
            this.onItemClick(this.filteredAutocompleteValues[this.selectionIndex])
            this.$refs.Input.blur()
          }
          break
        case 38: // Arrow up
          if (this.selectionIndex > 0) {
            this.selectionIndex--
          }
          break
        case 40: // Arrow down
          if (this.selectionIndex < this.filteredAutocompleteValues.length - 1) {
            this.selectionIndex++
          }
      }
    },
    onMouseUp (e) {
      this.cursorPosition = e.srcElement.selectionStart
    },
    $focus () {
      this.$refs.Input.$el.focus()
    }
  },
  created () {
    this.content = this.value
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/autocomplete-input.scss'
</style>
