<template>
  <b-container class="bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col> </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">Autocomplete Input demo</h1>
        </b-col>
      </b-row>
      <b-row no-gutters class="my-5">
        <b-col cols="12">
          <b-form-group
            label-cols="12"
            label-cols-md="2"
            label="Test this input"
          >
            <autocomplete-input v-model="inputVal" :values="autocompleteValues" @input="onInput" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <p>Start type value in input field and list with matched values will be appeared.</p>
          <p><strong>Available values for autocomplete: </strong>{{ autocompleteValues.join(', ') }}</p>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import AutocompleteInput from '@/components/common/AutocompleteInput.vue'

export default {
  name: 'AutocompleteInputExample',
  components: { AutocompleteInput },
  data () {
    return {
      inputVal: 'Al',
      autocompleteValues: [
        'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve',
        'Test', 'Allow', 'Nothing', 'None', 'Something', 'Thing', 'Emerald', 'Diamond', 'Gold', 'Silver', 'Bitcoin',
        'Bread', 'Bank', 'Currency', 'Estimation', 'Metal', 'Car', 'Bike', 'Coffee', 'Spirecta', 'Upwork',
        'Dog', 'Cat', 'Copycat', 'International', 'Countryside', 'Country', 'Transaction', 'First', 'Second', 'Third',
        'Absolute', 'Fixed', 'Trello', 'Bugherd', 'Slack', 'Code', 'Production', 'Development', 'Application'
      ]
    }
  },
  methods: {
    onInput (val) {
      // console.log(this.inputVal)
    }
  }
}
</script>
